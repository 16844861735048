import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useBooleanState, useTextFieldInput } from '@fingo/lib/hooks';
import UploadFileButton from '@fingo/lib/components/buttons/UploadFileButton';
import { Stack, TextField } from '@mui/material';
import LoadingIconButtonTooltip from '@fingo/lib/components/buttons/LoadingIconButtonTooltip';
import OperativeStepperDialog from '@fingo/lib/components/dialogs/OperativeStepperDialog';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import useResolveRatification from '../../../../../hooks/useResolveRatification';

const ResolveRatification = ({
  selectedInvoiceIds,
  setSelectedInvoices,
  accepted,
  stepToTitle,
  loadingIcon,
  loadingIconColor,
  iconTooltip,
}) => {
  const [resolutionType, setResolutionType] = useState('');
  const [open, toggleOpen,, closeDialog] = useBooleanState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [evidenceFile, setEvidenceFile] = useState(null);
  const [evidenceContactId, setEvidenceContactId] = useState([]);
  const [observation, setObservation, resetObservation] = useTextFieldInput();
  const clean = useCallback(() => {
    closeDialog();
    setResolutionType('');
    setCurrentStep(0);
    setEvidenceFile(undefined);
    setEvidenceContactId([]);
    resetObservation();
    setSelectedInvoices([]);
  }, []);
  const selectedContactsIds = evidenceContactId.length ? [evidenceContactId[0]] : [];
  const [resolveRatification, selectOptions, invoices] = useResolveRatification(
    {
      invoiceIds: selectedInvoiceIds,
      evidenceFile,
      evidenceContactId: selectedContactsIds[0],
      resolutionType,
      observation,
      accepted,
    },
    clean,
  );
  const receivers = [...new Set(invoices.map((invoice) => invoice.receiver))];
  return (
    <>
      <LoadingIconButtonTooltip
        tooltipTitle={iconTooltip}
        color={loadingIconColor}
        disabled={receivers.length !== 1}
        onClick={toggleOpen}
      >
        {loadingIcon}
      </LoadingIconButtonTooltip>
      <OperativeStepperDialog
        open={open}
        onClose={toggleOpen}
        selectedOption={resolutionType}
        setOptions={setResolutionType}
        selectedDocuments={selectedInvoiceIds}
        selectedContactsIds={selectedContactsIds}
        setSelectedContactsIds={setEvidenceContactId}
        firstStepOptions={selectOptions}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        operationType={CONTACT_TYPES.RATIFICATION}
        showAllContactTypes
        lastStepComponent={(
          <Stack alignItems="flex-start" width="100%" spacing={4}>
            <UploadFileButton color="primary" setFile={setEvidenceFile}>
              {evidenceFile ? evidenceFile.name : 'Adjuntar archivo'}
            </UploadFileButton>
            <TextField
              value={observation}
              onChange={setObservation}
              variant="standard"
              placeholder="Ingrese una observación"
              fullWidth
            />
          </Stack>
        )}
        onSubmit={resolveRatification[0]}
        submitButtonText="Aceptar"
        submitButtonId="resolve-ratification"
        loadingSubmit={resolveRatification[1].loading}
        stepToTitle={stepToTitle}
        obligatoryContactId={['phone'].includes(resolutionType)}
        masterEntityTarget={receivers[0]}
      />
    </>
  );
};

ResolveRatification.propTypes = {
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedInvoices: PropTypes.func.isRequired,
  accepted: PropTypes.bool.isRequired,
  stepToTitle: PropTypes.shape({
    0: PropTypes.string,
    1: PropTypes.string,
    2: PropTypes.string,
  }).isRequired,
  loadingIcon: PropTypes.node.isRequired,
  loadingIconColor: PropTypes.string.isRequired,
  iconTooltip: PropTypes.string.isRequired,
};

export default ResolveRatification;
