import CheckboxGroup from '@fingo/lib/components/selects/CheckboxGroup';
import React from 'react';
import PropTypes from 'prop-types';
import { EnumOptionsType } from '@fingo/lib/propTypes';
import { Stack, TextField } from '@mui/material';
import SelectRadioGroup from '@fingo/lib/components/selects/SelectRadioGroup';

const LastStepManualManagement = ({
  responded,
  toggleResponded,
  selectedChannel,
  setSelectedChannel,
  channelOptions,
  comment,
  setComment,
}) => (
  <Stack spacing={4} alignItems="flex-start">
    <SelectRadioGroup
      options={channelOptions}
      setOption={setSelectedChannel}
      selectedOption={selectedChannel}
    />
    {selectedChannel === 'PHONE_CALL' && (
    <CheckboxGroup
      options={[
        {
          checked: responded,
          label: '¿Contestó la llamada?',
          handleChange: toggleResponded,
        },
      ]}
    />
    )}
    <TextField
      value={comment}
      onChange={setComment}
      placeholder="Ingrese una observación"
      fullWidth
      variant="outlined"
    />
  </Stack>
);

LastStepManualManagement.propTypes = {
  responded: PropTypes.bool.isRequired,
  toggleResponded: PropTypes.func.isRequired,
  selectedChannel: PropTypes.string.isRequired,
  setSelectedChannel: PropTypes.func.isRequired,
  channelOptions: EnumOptionsType.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
};

export default LastStepManualManagement;
