import { DownloadExcelIconButton } from '@fingo/lib/components/buttons';
import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';
import ClearListButton from '@fingo/lib/components/buttons/ClearListButton';
import PropTypes from 'prop-types';
import React from 'react';
import { EXPORT_INVOICES } from '@fingo/lib/graphql';
import { useExportDocument } from '@fingo/lib/hooks';
import SiiStatusFilter from '@fingo/lib/components/filters/SiiStatusFilter';
import NoRatificationActionsFilter from '@fingo/lib/components/filters/NoRatificationActionsFilter';
import { Divider } from '@mui/material';
import AcceptRatification from '../ratificationResolutions/AcceptRatification';
import FetchTrace from '../ratificationResolutions/FetchTrace';
import ManualManagement from '../ratificationResolutions/ManualManagement';
import RejectRatification from '../ratificationResolutions/RejectRatification';
import SendRatificationMail from '../ratificationResolutions/SendRatificationMail';
import AllRatificatorsAsignedFilter from '../components/AllRatificatorsAsignedFilter';
import CommercialExecutiveFilter from '../components/CommercialExecutiveFilter';

const RatificationActions = ({
  selectedInvoiceIds,
  setSelectedInvoices,
  exportVariables,
  siiFilter,
  setSiiFilter,
  siiOptions,
  noRatificationActionsFilter,
  setNoRatificationActionsFilter,
  filterByRatificatorAsigned,
  setFilterByRatificatorAssigned,
  commercialExecutiveFilter,
  setCommercialExecutiveFilter,
}) => {
  const {
    exportDocuments: exportInvoices,
    loading: exportingDocuments,
  } = useExportDocument(EXPORT_INVOICES, {
    ...exportVariables,
    rowCount: 10,
    extraFields: [
      'date_to_pay',
      'company__executive_assigned__last_name',
      'ratificationmanager__ratificator__last_name',
      'collection_manager_action_count',
      'ratification_action_count',
    ] });
  return (
    <>
      <SiiStatusFilter filter={siiFilter} setFilter={setSiiFilter} options={siiOptions} />
      <AllRatificatorsAsignedFilter
        filter={filterByRatificatorAsigned}
        setFilter={setFilterByRatificatorAssigned}
      />
      <CommercialExecutiveFilter
        filter={commercialExecutiveFilter}
        setFilter={setCommercialExecutiveFilter}
      />
      <NoRatificationActionsFilter
        filter={noRatificationActionsFilter}
        setFilter={setNoRatificationActionsFilter}
      />
      <Divider orientation="vertical" variant="middle" sx={{ height: '32px', alignSelf: 'center' }} flexItem />
      <RaiseOperativeRequestIconButton
        selectedInvoices={selectedInvoiceIds}
        setDocumentIds={setSelectedInvoices}
      />
      <AcceptRatification
        selectedInvoiceIds={selectedInvoiceIds}
        setSelectedInvoices={setSelectedInvoices}
      />
      <RejectRatification
        selectedInvoiceIds={selectedInvoiceIds}
        setSelectedInvoices={setSelectedInvoices}
      />
      <SendRatificationMail selectedInvoiceIds={selectedInvoiceIds} />
      <ManualManagement selectedInvoiceIds={selectedInvoiceIds} />
      <FetchTrace selectedInvoiceIds={selectedInvoiceIds} />
      <ClearListButton
        setList={setSelectedInvoices}
        selectedInvoiceIds={selectedInvoiceIds}
      />
      <DownloadExcelIconButton downloadFunction={exportInvoices} loading={exportingDocuments} />
    </>
  );
};

RatificationActions.propTypes = {
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedInvoices: PropTypes.func.isRequired,
  exportVariables: PropTypes.shape().isRequired,
  siiFilter: PropTypes.string.isRequired,
  setSiiFilter: PropTypes.func.isRequired,
  siiOptions: PropTypes.objectOf(PropTypes.string).isRequired,
  noRatificationActionsFilter: PropTypes.bool.isRequired,
  setNoRatificationActionsFilter: PropTypes.func.isRequired,
  filterByRatificatorAsigned: PropTypes.bool.isRequired,
  setFilterByRatificatorAssigned: PropTypes.func.isRequired,
  commercialExecutiveFilter: PropTypes.bool.isRequired,
  setCommercialExecutiveFilter: PropTypes.func.isRequired,
};

export default RatificationActions;
