import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { UploadDropZoneDialog, FingoDialog } from '@fingo/lib/components/dialogs';
import { FingoDatePicker } from '@fingo/lib/components/datePickers';
import { Typography, Stack, Tooltip, TextField } from '@mui/material';
import { useBooleanState } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import {
  GET_COMPANY_CONTRACT_GENERATION_SIMPLE,
  GENERATE_PENDING_SIGNATURE_CONTRACT_ANNEX,
  PENDING_SIGNATURE,
  UPLOAD_INVOICE_SIGNATURE,
} from '@fingo/lib/graphql';
import { SelectBankAccount } from '@fingo/lib/components/selects';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';
import { validateRfc } from '@fingo/lib/helpers';
import UploadSignaturePersonComponent from './UploadSignaturePersonComponent';
import HandleResponse from './HandleResponse';

const UploadSignatureDialog = ({ companyId, selectedDocuments, invoices, currency }) => {
  const [,, fetchGivenId] = useFetchPresignedUrl();
  const openUrl = useCallback((globalAppId) => fetchGivenId(globalAppId), [fetchGivenId]);
  const openSecondUrl = useCallback((globalAppId) => fetchGivenId(globalAppId, 'presigned_second_file_attribute'), [fetchGivenId]);
  const [openDialog, setOpenDialog] = useBooleanState();
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [bankId, setBankId] = useState('');
  const [companyContractName, setCompanyContractName] = useState('');
  const [signatureDate, setSignatureDate] = useState(moment());
  const [legalRepresentatives, setLegalRepresentatives] = useState([]);
  const [endorsements, setEndorsements] = useState([]);

  const DisableAnnexButton = useMemo(() => {
    if (!selectedDocuments.length) return true;
    const filteredInvoices = invoices.filter((inv) => selectedDocuments.includes(inv.id));
    for (let i = 0; i < filteredInvoices.length; i += 1) {
      if (filteredInvoices[i].pendingsignaturecontractannexinvoice) return true;
    }
    return false;
  }, [selectedDocuments]);

  const [uploadInvoiceSignature,
    { loading: loadingUploadSignature },
  ] = useMutation(UPLOAD_INVOICE_SIGNATURE, {
    variables: {
      invoiceIds: selectedDocuments,
      inputUploadFile: files[0],
    },
    refetchQueries: [PENDING_SIGNATURE],
    onCompleted: () => {
      setOpenSignatureDialog(false);
      setFiles([]);
    },
  });

  const [GetCompanyContractGeneration, { loading }] = useLazyQuery(
    GET_COMPANY_CONTRACT_GENERATION_SIMPLE,
    {
      variables: { companyId },
      notifyOnNetworkStatusChange: true,
      skip: !openDialog,
      onCompleted: ({ company }) => {
        const lastContractAnnex = company?.lastPendingSignatureContractAnnex;
        const selectedName = lastContractAnnex?.companyContractName ?? company.name.toUpperCase();
        setCompanyContractName(selectedName);
        const sigDate = lastContractAnnex ? moment(lastContractAnnex.signatureDate) : moment();
        setSignatureDate(sigDate);
        HandleResponse(company, setSelectedCompany, setLegalRepresentatives, setEndorsements);
        setOpenDialog();
      },
    },
  );
  const legalRepresentativesFinalArray = useMemo(() => legalRepresentatives.map((legalRep) => {
    const { id, ...rest } = legalRep;
    return rest;
  }), [legalRepresentatives]);

  const endorsementFinalArray = useMemo(() => endorsements.map((end) => {
    const { id, ...rest } = end;
    return rest;
  }), [endorsements]);

  const [CreatePendingSignatureContractAnnex, { loading: generateLoading }] = useMutation(
    GENERATE_PENDING_SIGNATURE_CONTRACT_ANNEX,
    {
      variables: {
        companyId,
        companyContractName,
        signatureDate: signatureDate.format('YYYY-MM-DD'),
        invoiceIds: selectedDocuments,
        bankAccountId: bankId,
        legalRepresentatives: legalRepresentativesFinalArray,
        endorsements: endorsementFinalArray,
      },
      onCompleted: ({ createPendingSignatureContractAnnex }) => {
        openUrl(createPendingSignatureContractAnnex.contractAnnex.globalAppId);
        openSecondUrl(createPendingSignatureContractAnnex.contractAnnex.globalAppId);
        setOpenDialog();
      },
      refetchQueries: [PENDING_SIGNATURE],
    },
  );
  const handleAddEndorsement = () => {
    const newItem = [{
      id: endorsements.length,
      masterEntityId: '',
      rut: '',
      name: '',
    }];
    setEndorsements(endorsements.concat(newItem));
  };

  const handleDeleteEndorsement = (id) => {
    setEndorsements(endorsements.filter((item) => item.id !== id));
  };

  const handleAddLegalRep = () => {
    const newItem = [{
      id: legalRepresentatives.length,
      masterEntityId: '',
      rut: '',
      name: '',
      address: '',
    }];
    setLegalRepresentatives(legalRepresentatives.concat(newItem));
  };

  const handleDeleteLegalRepresentative = (id) => {
    setLegalRepresentatives(legalRepresentatives.filter((item) => item.id !== id));
  };

  const handleLegalRepresentativeInfo = (eventId, eventValue, legalRepresentativeId) => {
    const newItem = legalRepresentatives.find((item) => item.id === legalRepresentativeId);
    newItem[eventId] = eventValue;
    setLegalRepresentatives(legalRepresentatives.map((u) => (u.id !== newItem.id ? u : newItem)));
  };

  const handleEndorsementInfo = (eventId, eventValue, endorsementId) => {
    const newItem = endorsements.find((item) => item.id === endorsementId);
    newItem[eventId] = eventValue;
    setEndorsements(endorsements.map((u) => (u.id !== newItem.id ? u : newItem)));
  };

  const disableConfirmButton = useMemo(() => {
    if (
      !bankId || !signatureDate || !legalRepresentatives.length || !companyContractName
    ) return true;
    for (let i = 0; i < endorsements.length; i += 1) {
      if (
        !validateRfc(endorsements[i].rut)
        || !endorsements[i].name
        || !endorsements[i].address
      ) return true;
    }
    for (let i = 0; i < legalRepresentatives.length; i += 1) {
      if (
        !validateRfc(legalRepresentatives[i].rut)
        || !legalRepresentatives[i].name
        || !legalRepresentatives[i].address
      ) return true;
    }
    return false;
  }, [bankId, signatureDate, endorsements, legalRepresentatives, companyContractName]);
  const disableButtonLabel = useMemo(() => {
    if (!selectedDocuments.length) return 'Debes Seleccionar Facturas';
    if (DisableAnnexButton) return 'Hay facturas seleccionadas que ya tienen un anexo de contrato generado';
    return '';
  }, [DisableAnnexButton, selectedDocuments]);
  return (
    <>
      <Tooltip title={disableButtonLabel}>
        <span>
          <LoadingButton
            color="primary"
            size="small"
            variant="contained"
            disabled={DisableAnnexButton}
            onClick={GetCompanyContractGeneration}
            loading={loading}
          >
            Descargar Anexo
          </LoadingButton>
        </span>
      </Tooltip>
      <Tooltip title={(!selectedDocuments.length) ? 'Debes Seleccionar Facturas' : ''}>
        <span>
          <LoadingButton
            color="primary"
            size="small"
            variant="contained"
            disabled={!selectedDocuments.length}
            onClick={setOpenSignatureDialog}
          >
            Subir Firma
          </LoadingButton>
        </span>
      </Tooltip>
      <FingoDialog
        open={openDialog}
        handleClose={setOpenDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title="Subir Firmas"
        maxWidth="sm"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disabled={disableConfirmButton}
            loading={generateLoading}
            onClick={CreatePendingSignatureContractAnnex}
          >
            Subir
          </LoadingButton>
      )}
      >
        <Stack spacing={1.5}>
          <Typography>Nombre de Empresa en Anexo</Typography>
          <TextField
            id="name"
            label="Nombre Empresa"
            onChange={(e) => setCompanyContractName(e.target.value.toUpperCase())}
            value={companyContractName}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Typography>Elegir Fecha de Contrato</Typography>
          <FingoDatePicker
            onChange={(value) => setSignatureDate(moment(value))}
            value={signatureDate.toISOString()}
          />
          {selectedCompany && (
            <>
              <Typography>Elegir Cuenta Bancaria</Typography>
              <SelectBankAccount
                customCompany={selectedCompany}
                customBankAccounts={selectedCompany.bankAccounts ?? []}
                setBankId={setBankId}
                bankId={bankId}
                hideText
                currency={currency}
              />
            </>
          )}
          <UploadSignaturePersonComponent
            personArray={legalRepresentatives}
            handleSetPersonArray={handleAddLegalRep}
            handleDeletePersonArray={handleDeleteLegalRepresentative}
            handleInformation={handleLegalRepresentativeInfo}
            label="Representantes Legales"
          />
          <UploadSignaturePersonComponent
            personArray={endorsements}
            handleSetPersonArray={handleAddEndorsement}
            handleDeletePersonArray={handleDeleteEndorsement}
            handleInformation={handleEndorsementInfo}
            label="Obligados Solidarios"
          />
        </Stack>
      </FingoDialog>
      <UploadDropZoneDialog
        open={openSignatureDialog}
        setOpen={setOpenSignatureDialog}
        onClose={() => setOpenSignatureDialog(false)}
        message="Subir firma"
        buttonMessage="Subir documento"
        files={files}
        showExcelOptions={false}
        setFiles={setFiles}
        onCompleted={uploadInvoiceSignature}
        loading={loadingUploadSignature}
      />
    </>
  );
};

UploadSignatureDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    amountWithIva: PropTypes.shape({
      currency: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
  })).isRequired,
  currency: PropTypes.string.isRequired,
};

export default UploadSignatureDialog;
