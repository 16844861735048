import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';
import Assignment from '@mui/icons-material/Assignment';
import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@emotion/react';

const DownloadAnnexComponent = ({ invoice }) => {
  const [,, fetchGivenId] = useFetchPresignedUrl();
  const openUrl = useCallback((model) => fetchGivenId(model.globalAppId), [fetchGivenId]);
  const theme = useTheme();
  const SUCCESS = theme.palette.success.main;
  const annexInvoice = invoice.pendingsignaturecontractannexinvoice;
  return (
    <Tooltip title={annexInvoice ? 'Descargar Anexo Generado' : 'Sin Anexo Generado'}>
      <span>
        <IconButton
          sx={{ color: SUCCESS }}
          disabled={!annexInvoice}
          onClick={() => openUrl(annexInvoice.pendingSignatureDocument)}
        >
          <Assignment />
        </IconButton>
      </span>
    </Tooltip>
  );
};

DownloadAnnexComponent.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    pendingsignaturecontractannexinvoice: PropTypes.shape({
      id: PropTypes.string.isRequired,
      pendingSignatureDocument: PropTypes.shape({
        id: PropTypes.string.isRequired,
        globalAppId: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default DownloadAnnexComponent;
